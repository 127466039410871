import React from 'react';

const IconYoutube = () => (
  // <svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   role="img"
  //   viewBox="0 0 24 24"
  //   fill="none"
  //   stroke="currentColor"
  //   strokeWidth="2"
  //   strokeLinecap="round"
  //   strokeLinejoin="round"
  //   className="feather feather-linkedin">
  //   <title>LinkedIn</title>
  //   <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
  //   <rect x="2" y="9" width="4" height="12"></rect>
  //   <circle cx="4" cy="4" r="2"></circle>
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3333 2376" 
  shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" 
  fill-rule="evenodd" clip-rule="evenodd">
    <path d="M3193 548c-4-24-34-208-113-287l-1-1c-97-101-207-112-269-118l-20-2c-448-33-1121-33-1123-33h-1c-2 0-674 0-1123 33-3 0-12 1-22 2-62 6-172 17-268 118l-1 1c-80 81-110 271-112 288 0 1-32 258-32 516v244c0 258 32 515 32 516v1c3 20 33 208 113 288l1 1c88 92 204 106 279 115 17 2 32 4 46 7 259 24 1087 32 1090 32 17 0 678-2 1124-33l20-2c62-6 172-17 269-118l1-1c80-81 110-271 113-288 0-1 32-258 32-516v-244c-1-235-27-472-32-511 0-2-1-4-1-7zm-36-362c99 101 136 306 142 343 1 2 1 5 1 7 0 1 32 263 33 529v244c0 264-32 528-33 529v1c0 1-34 238-142 349l-1 1c-125 130-260 144-337 151-4 0-8 1-19 2h-3c-451 32-1131 33-1133 33s-836-7-1099-32c-2 0-5 0-7-1-10-2-24-4-41-6-89-11-229-28-344-148-102-104-138-321-142-347 0-1 0-3-1-4 0-1-32-265-32-529v-244c0-264 32-528 33-529v-1c0-1 33-238 141-349l1-1C298 54 433 41 510 33c5 0 9-1 20-2h3C987-2 1663-2 1665-2h1c2 0 677 0 1131 33h4c12 1 15 2 19 2 76 7 212 21 337 151zM1279 1606V699c0-30 24-54 54-54 11 0 21 3 29 9l867 453c26 14 36 46 23 72-5 10-13 18-23 23l-871 452c-26 14-59 3-72-23-4-8-6-16-6-25zm108-818v730l702-364-702-366z" fill-rule="nonzero"/>
    </svg>
);

export default IconYoutube;
