import React from 'react';

const IconCodeforces = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-bookmark">
    <title>Codeforces</title>
	<polyline points="3.4,22 3.4,11.4 8.8,11.4 8.8,22 14.9,22 14.9,4.5 8.8,4.5 8.8,11.4 8.8,22 14.9,22 20.7,22 
		20.7,13.2 14.9,13.2 14.9,22,3.4"/>

</svg>
);

export default IconCodeforces;