import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
      <path class="cls-1" d="M.5,19.09V68.64a1.27,1.27,0,0,0,.66,1.11L41.89,92.07a1.26,1.26,0,0,0,1.22,0L83.84,69.75a1.27,1.27,0,0,0,.66-1.11V24.09A1.27,1.27,0,0,0,83.84,23L43.11.66a1.26,1.26,0,0,0-1.22,0L1.16,23A1.27,1.27,0,0,0,.5,24.09Z"/><path class="cls-2" d="M56.15,54.7a9.3,9.3,0,0,1-3.89,7.95c-2.59,1.9-6.12,2.85-10.56,2.85q-7.23,0-11.12-1.77V59.4a31.55,31.55,0,0,0,11.28,2.16c3.15,0,5.53-.56,7.12-1.7a5.48,5.48,0,0,0,2.39-4.73,5.8,5.8,0,0,0-.85-3.29,7.75,7.75,0,0,0-2.83-2.36,41.54,41.54,0,0,0-6-2.46Q36,45.1,33.54,42.46a9.78,9.78,0,0,1-2.43-6.89,8.4,8.4,0,0,1,3.53-7.1q3.53-2.64,9.34-2.64A28.72,28.72,0,0,1,55.12,28l-1.47,3.9a26.27,26.27,0,0,0-9.78-2A9.85,9.85,0,0,0,38,31.38a4.93,4.93,0,0,0-2.11,4.24,6.24,6.24,0,0,0,.78,3.29,7.19,7.19,0,0,0,2.62,2.35A33.89,33.89,0,0,0,45,43.62q6.39,2.16,8.8,4.64A8.88,8.88,0,0,1,56.15,54.7Z"
      fill="currentColor"
      />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
