import React from 'react';
const IconItch = () => (
<svg 
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 96 61"
    fill="none"
    stroke="currentColor"
    strokeWidth="8  "
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-bookmark">
    <title>Itch</title>

    <path class="a" d="M334.6,430.7a8.1,8.1,0,0,0,0-3.1,3.4,3.4,0,0,1-.1-1,47.3,47.3,0,0,0-.4-5.3,111.3,111.3,0,0,0-2.3-12.6,63.7,63.7,0,0,0-5.2-14c-1.9-3.6-4.2-6.9-7.7-9.1a14.6,14.6,0,0,0-9.1-2.2c-5,.3-9.7,1.9-14.6,3-3,.6-6,1.4-9.2,1.2a38.9,38.9,0,0,1-8.3-1.4,118.2,118.2,0,0,0-11.8-2.6c-5.9-.9-11.1.5-15.1,5.3a39.6,39.6,0,0,0-7,12.7,87.5,87.5,0,0,0-4.2,17.5,76.1,76.1,0,0,0-.8,9.5,32.4,32.4,0,0,0,1,9.8,8.7,8.7,0,0,0,3.6,5.3c2,1.2,4.2,1,6.4.4a32.4,32.4,0,0,0,9.3-5.1c2.9-2.1,5.5-4.5,8.2-6.8a23,23,0,0,1,9.3-4.5,38.1,38.1,0,0,1,12.1-1.3,34.6,34.6,0,0,1,13.8,3.4,23,23,0,0,1,4.7,3.5c4.1,3.6,8.3,7.3,13.4,9.7a14.1,14.1,0,0,0,6.4,1.6,6.2,6.2,0,0,0,5.2-3.2c1.5-2.2,1.9-4.8,2.2-7.3S334.5,431.8,334.6,430.7Z" transform="translate(-238.6 -383.2)"/>
<path class="a" d="M276.2,407.3a4.8,4.8,0,0,0-2.6-4.4,3,3,0,0,0-1.6-.5c-2.2-.3-2.2-.3-2.5-2.3a4.8,4.8,0,0,0-2.7-4,5,5,0,0,0-7.1,3.9c-.2,1.9-.2,2.1-2.4,2.4a4.7,4.7,0,0,0-4.1,3,4.9,4.9,0,0,0,.8,4.8,4.6,4.6,0,0,0,3.6,1.9c1.6.1,2,.5,2.1,2.2a4.8,4.8,0,0,0,4,4.4,4.7,4.7,0,0,0,5.2-2.6,5.5,5.5,0,0,0,.7-2.4,1.4,1.4,0,0,1,1.4-1.4l1.4-.2a4.9,4.9,0,0,0,3.7-4.2,4.3,4.3,0,0,1,.1-.5" transform="translate(-238.6 -383.2)"/>
<polygon class="a" points="82 20.3 76.5 20.3 76.5 14.8 69.2 14.8 69.2 20.3 63.7 20.3 63.7 27.6 69.2 27.6 69.2 33.1 76.5 33.1 76.5 27.6 82 27.6 82 20.3"/>        
</svg>
    );

export default IconItch;


